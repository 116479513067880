import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Card, CardActionArea, CardMedia, CardContent } from '@mui/material';

function Index() {
    const cxbCard = (
        <Card sx={{ maxWidth: 345 }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height={window.innerWidth > 600 ? "300" : "100"}
            image="/static/cxb.jpg"
            alt="cxb"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              蔡徐博
            </Typography>
            <Typography variant="body2" color="text.secondary">
              法人、股东 中国传媒大学2023级
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
    const tyxCard = (
        <Card sx={{ maxWidth: 345 }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height={window.innerWidth > 600 ? "300" : "100"}
            image="/static/tyx.jpg"
            alt="tyx"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              唐语昕
            </Typography>
            <Typography variant="body2" color="text.secondary">
              股东 南京航空航天大学2023级
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
    // 大屏设备并排显示，小屏设备上下显示
    return (
        <Box>
            <Typography variant="h5" component="div" gutterBottom>
                公司简介
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;南京炡云数字科技有限公司成立于 2023年8月30日 ,位于江苏南京，是一家以软件开发为主的有限责任公司。 
            </Typography>
            <Typography variant="h5" component="div" gutterBottom>
                主要业务
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;· 互联网信息服务
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;· 软件开发服务
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;· 人工智能业务
            </Typography>
            <Typography variant="h5" component="div" gutterBottom>
                主要人员组成
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', p: 1, m: 1, bgcolor: 'background.paper' }}>
                <Box sx={{ p: 1, m: 1,  }}>{cxbCard}</Box>
                <Box sx={{ p: 1, m: 1,}}>{tyxCard}</Box>
            </Box>

        </Box>
    )
}

export default Index

/**
 * 
 * 'use client';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Card, CardActionArea, CardMedia, CardContent } from '@mui/material';



export default function Index() {
    const cxbCard = (
        <Card sx={{ maxWidth: 345 }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image="https://img1.imgtp.com/2023/09/06/4QXVhRcH.png"
            alt="cxb"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              蔡徐博
            </Typography>
            <Typography variant="body2" color="text.secondary">
              法人、股东 中国传媒大学2023级
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
    const tyxCard = (
        <Card sx={{ maxWidth: 345 }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image="https://img1.imgtp.com/2023/09/06/sXEDBToX.png"
            alt="tyx"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              唐语昕
            </Typography>
            <Typography variant="body2" color="text.secondary">
              股东 南京航空航天大学2023级
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
    // 大屏设备并排显示，小屏设备上下显示
    return (
        <Box>
            <Typography variant="h5" component="div" gutterBottom>
                公司简介
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;南京炡云数字科技有限公司成立于 2023年8月30日 ,位于江苏南京，是一家以软件开发为主的有限责任公司。 
            </Typography>
            <Typography variant="h5" component="div" gutterBottom>
                主要业务
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;· 互联网信息服务
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;· 软件开发服务
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;· 人工智能业务
            </Typography>
            <Typography variant="h5" component="div" gutterBottom>
                主要人员组成
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', p: 1, m: 1, bgcolor: 'background.paper' }}>
                <Box sx={{ p: 1, m: 1,  }}>{cxbCard}</Box>
                <Box sx={{ p: 1, m: 1,}}>{tyxCard}</Box>
            </Box>

        </Box>
    )
}
 */