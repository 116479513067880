import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Card, CardActionArea, CardMedia, CardContent } from '@mui/material';

function Projects() {
    const xcampusCard = (
        <Card sx={{ maxWidth: 345 }}>
            <CardActionArea
                onClick={() => {
                    window.open('http://xcampus.zhengcloud.ltd');
                }
                }
            >
                <CardMedia
                    component="img"
                    height={window.innerWidth > 600 ? "300" : "100"}
                    image="/static/xcampus.png"
                    alt="XCampus Logo"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        XCampus APP
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        一款面向大学生和办公人群的APP
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
    return (
        <Box>
            {xcampusCard}
        </Box>
    )
}

export default Projects